<template>
  <section class="mt-1 px-5 ml-4 top-sec">
    <nav class="left-steps">
      <div class="step-num active">1</div>
      <div class="step-line active-line"></div>
      <div class="step-num" :class="curStep >= 2 ? 'active' : ''">2</div>
      <div class="step-line" :class="curStep >= 2 ? 'active-line' : ''"></div>
      <div class="step-num" :class="curStep == 3 ? 'active' : ''">3</div>
    </nav>
    <nav class="my-steps mt-2 mb-3">
      <span
        class="clip-others selected-clip"
        :style="curStep !== 1 ? 'background: #bababa; color: var(--dark);' : ''"
        >步驟一</span
      >
      <span
        class="clip-others clips-left"
        :style="curStep == 2 ? 'background: #965ff0;color:white;' : ''"
        >步驟二</span
      >
      <span
        class="clip-others clips-left2"
        :style="curStep == 3 ? 'background: #965ff0;color:white;' : ''"
        >步驟三</span
      >
    </nav>

    <main class="main-grid-dtc">
      <b-input-group prepend="選擇衛生所">
        <b-select
          v-model="myHealthCenter"
          :options="healthCenters"
          :disabled="curStep != 1"
        ></b-select>
      </b-input-group>
      <b-input-group prepend="見習類別">
        <b-select
          v-model="myTrainee"
          :options="traineeCats"
          style="font-size:1rem !important;"
          disabled
        ></b-select>
      </b-input-group>
      <b-input-group prepend="參與類別">
        <b-select
          v-model="myParticipate"
          :options="participateCats"
          :disabled="curStep != 1"
        ></b-select>
      </b-input-group>

      <div style="grid-column: 1/-1;" class="chooseTime">
        <div class="calendarClass" @click="showCalendar = !showCalendar">
          <i class="fas fa-calendar-alt "></i>
        </div>
        <nav class="my-mask" v-if="curStep != 1"></nav>
        <b-input-group prepend="選擇時段">
          <b-input
            id="popover-target-1"
            v-model="selectedTimeStr"
            readonly
          ></b-input>
          <b-popover
            target="popover-target-1"
            triggers="click"
            placement="rightbottom"
            :show.sync="showCalendar"
          >
            <template #title>{{ myTrainee }}見習選擇時段</template>
            <v-calendar
              locale="zh-TW"
              :attributes="attributes"
              @click.prevent="onDayClick"
              @dayclick="onDayClick"
            >
            </v-calendar>
            <b-popover
              target="popover-target-2"
              :show.sync="showTimeRange"
              placement="bottom"
              id="my-2-popup"
            >
              <div
                style="background:black;color:white;box-shadow: 0 0 0 20px black;transform:translate3d(58px,-32px,0)"
              >
                <h4>選擇時段: {{ days.length && days[days.length - 1].id }}</h4>
                <b-form-radio-group
                  :options="timeOptions"
                  v-model="currentTimeRange"
                >
                </b-form-radio-group>
              </div>
            </b-popover>
          </b-popover>
        </b-input-group>
      </div>
      <b-button
        variant="success"
        class="nextStep"
        @click="goNextStep"
        v-if="curStep == 1"
        >下一步</b-button
      >
      <b-button
        variant="success"
        class="nextStep"
        @click="curStep = 1"
        v-if="curStep == 2"
        >上一步</b-button
      >
    </main>
    <main class="main-grid-step2 mt-3 px-4 py-3">
      <b-input
        readonly
        style="grid-column: 1/-1;text-align:center;font-size:20px"
        v-model="myTitle"
      ></b-input>
      <b-input-group prepend="姓名">
        <b-input v-model="myDate" readonly></b-input>
      </b-input-group>
      <b-input-group prepend="性別代碼">
        <b-form-radio-group
          v-model="myGender"
          :options="genderOptions"
          class="ml-3"
          disabled
        >
        </b-form-radio-group>
      </b-input-group>
      <b-input-group prepend="身分證號">
        <b-input v-model="myDate" readonly></b-input>
      </b-input-group>
      <b-input-group prepend="是否為地方養成公費生">
        <b-form-radio-group
          v-model="mIsPublicStudent"
          :options="publicStudentOptions"
          class="ml-3"
          disabled
        >
        </b-form-radio-group>
      </b-input-group>
      <b-input-group prepend="出生年月日">
        <b-input v-model="myDate" readonly></b-input>
      </b-input-group>
      <b-input-group prepend="族別/離島島嶼名稱">
        <b-input v-model="myDate" readonly></b-input>
      </b-input-group>
      <b-input-group prepend="年級">
        <b-form-radio-group
          v-model="myGrade"
          :options="gradeOptions"
          class="gradeStyle"
          disabled
        >
        </b-form-radio-group>
      </b-input-group>
      <b-input-group prepend="就讀學校/PGY機構">
        <b-input v-model="myDate" readonly id="popover-target-2"></b-input>
      </b-input-group>
      <b-input-group prepend="學校名稱">
        <b-input v-model="myDate" readonly></b-input>
      </b-input-group>
      <b-input-group prepend="科系">
        <b-input v-model="myDate" readonly></b-input>
      </b-input-group>
      <b-input-group prepend="市內電話">
        <b-input v-model="myTelePhone" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <b-input-group prepend="手機">
        <b-input v-model="myCellPhone" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <b-input-group prepend="Email">
        <b-input v-model="myEmail" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <b-input-group prepend="Line ID">
        <b-input v-model="myLine" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <b-input-group prepend="緊急聯絡人姓名">
        <b-input v-model="myContactName" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <b-input-group prepend="Facebook帳號">
        <b-input v-model="myFacebook" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <b-input-group prepend="緊急聯絡人電話">
        <b-input v-model="myContactPhone" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <b-input-group prepend="緊急聯絡人和本人關係為">
        <b-input
          v-model="myContactRel"
          placeholder="例: 父子"
          :disabled="curStep != 2"
        ></b-input>
      </b-input-group>
      <b-input-group prepend="通訊地址" style="grid-column: 1/-1;">
        <b-input v-model="myAddress" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <b-input-group prepend="銀行代碼">
        <b-input v-model="myBankCode" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <b-input-group prepend="戶名">
        <b-input v-model="myAccount" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <b-input-group prepend="銀行帳號" style="grid-column: 1/-1;">
        <b-input v-model="myBankName" :disabled="curStep != 2"></b-input>
      </b-input-group>
      <div style="grid-column: 1/-1;" class="ml-5">
        *除了郵局以外的帳戶，匯款金額會扣除臨櫃手續費30元
      </div>
      <div style="grid-column: 1/-1;text-align: right;">
        <b-button
          variant="primary"
          class="nextStep mr-2"
          @click="submitForm"
          :disabled="curStep != 2"
          >確認送出</b-button
        >
      </div>
    </main>
    <main class="main-grid-step2 mt-5 ml-5 mb-5" style="border:none">
      <div style="grid-column: 1/-1;">
        <b-button variant="success" class="nextStep mr-2">報到完成</b-button>
      </div>
    </main>
  </section>
</template>

<script>
import queryString from "query-string";
import moment from "moment";

const zero = "T00:00:00";

const traineeCats = ["一般", "彈性"];

const participateCats = ["觀察", "實作"];

const genderOptions = ["男", "女"];

const publicStudentOptions = ["是", "否"];

const gradeOptions = [
  "一",
  "二",
  "三",
  "四",
  "五",
  "六",
  "七",
  "PGY第一年",
  "PGY第二年",
];

const timeOptions = [
  "上午 09:00-12:00",
  "下午 13:00-17:00",
  "整天 09:00-17:00",
];

export default {
  name: "Apply12",
  data() {
    return {
      healthCenters: [],
      allHealthCentersData: [],
      curStep: 1,
      traineeCats,
      myTrainee: "一般",
      participateCats,
      genderOptions,
      publicStudentOptions,
      gradeOptions,
      myGrade: "",
      mIsPublicStudent: false,
      myHealthCenter: "",
      myParticipate: "",
      myDate: "",
      myGender: "",
      myTitle: "基本資料",
      myTelePhone: "",
      myCellPhone: "",
      myEmail: "",
      myLine: "",
      myContactName: "",
      myFacebook: "",
      myContactPhone: "",
      myContactRel: "",
      myAddress: "",
      myBankCode: "",
      myAccount: "",
      myBankName: "",
      days: [],
      showTimeRange: false,
      showCalendar: false,
      timeOptions,
      currentTimeRange: "整天 09:00-17:00",
    };
  },
  components: {},
  computed: {
    selectedTimeStr() {
      if (!this.days?.length) return "";
      const arr = this.days.map((s) => {
        return s.id + "(" + s.time.split(" ")[0] + ")";
      });
      return arr
        .sort((a, b) => a.split("(")[0].localeCompare(b.split("(")[0]))
        .join("  ");
    },
    dates() {
      return this.days.map((day) => day.date);
    },
    attributes() {
      return this.dates.map((date) => ({
        highlight: true,
        dates: date,
      }));
    },
  },

  methods: {
    isHolidays(day) {
      const isoDay = moment(day.id).isoWeekday();
      if (isoDay > 5) {
        // skip sat and sun days
        return true;
      }
      return false;
    },
    pickFlexDay(day) {
      let head = "";
      let dayList = [];
      const construct7days = () => {
        dayList = [head];
        let i = 1;
        while (dayList.length != 7) {
          dayList.push(
            moment(head)
              .add(i, "days")
              .format("YYYY-MM-DD")
          );
          ++i;
        }
      };
      const ym = moment(day.id).format("YYYY-MM");
      if (this.days.length) {
        const str = moment(this.days[0].id).format("YYYY-MM");
        str != ym ? (this.days = []) : "";
      }
      const isoDay = moment(day.id).isoWeekday();
      if (isoDay != 1) {
        head = moment(day.id)
          .subtract(isoDay - 1, "days")
          .format("YYYY-MM-DD");
        construct7days();
      } else {
        head = day.id;
        construct7days();
      }
      // check param's day is within the dayList
      let within7Days = false;
      for (let i = 0; i < 7; ++i) {
        let found = this.days.find((s) => s.id == dayList[i]);
        if (found) {
          within7Days = found;
          break;
        }
      }
      if (within7Days) {
        // de-select the previous day
        const arr = [];
        this.days.forEach((s) => {
          s.id != within7Days.id ? arr.push(s) : "";
        });
        this.days = [...arr];
      }
      this.days.push({
        id: day.id,
        date: day.date,
        time: this.currentTimeRange,
      });
      setTimeout(() => (this.showTimeRange = true), 300);
    },
    pickNormalDay(day) {
      const resetDays = () => {
        this.days = [];
        this.days.push({
          id: day.id,
          date: day.date,
          time: this.currentTimeRange,
        });
        setTimeout(() => (this.showTimeRange = true), 300);
      };
      if (!this.days.length || this.days.length == 3) {
        resetDays();
        return;
      }
      if (this.isDayHasNeighbor(day)) {
        this.days.push({
          id: day.id,
          date: day.date,
          time: this.currentTimeRange,
        });
        setTimeout(() => (this.showTimeRange = true), 300);
      } else {
        resetDays();
      }
    },
    isDayHasNeighbor(day, multi) {
      const next = moment(day.id)
        .add("1", "days")
        .format("YYYY-MM-DD");
      const prev = moment(day.id)
        .subtract("1", "days")
        .format("YYYY-MM-DD");
      const myNext = this.days.find((s) => s.id == next);
      const myPrev = this.days.find((s) => s.id == prev);
      if (!multi) {
        if (myNext || myPrev) return true;
        else return false;
      } else {
        if (myNext && myPrev) return true;
        else return false;
      }
    },
    goNextStep() {
      if (!this.myHealthCenter || !this.myHealthCenter.length) {
        this.$bvToast.toast("選擇衛生所", {
          title: "必選",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      if (!this.myParticipate || !this.myParticipate.length) {
        this.$bvToast.toast("選擇參與類別", {
          title: "必選",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      if (!this.selectedTimeStr.length) {
        this.$bvToast.toast("請選擇時段", {
          title: "必選",
          autoHideDelay: 5000,
          variant: "danger",
        });
        return;
      }
      this.curStep = 2;
    },
    onDayClick(day) {
      this.showTimeRange = false;
      const idx = this.days.findIndex((d) => d.id === day.id);

      if (
        this.myTrainee === "一般" &&
        idx >= 0 &&
        this.isDayHasNeighbor(day, true)
      ) {
        this.days = [];
        return;
      }

      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else if (!this.isHolidays(day)) {
        this.myTrainee == "彈性"
          ? this.pickFlexDay(day)
          : this.pickNormalDay(day);
      }
    },
    async getData() {
      try {
        this.allHealthCentersData = await window.axios.get(
          `Training/GetHCTraining?startdate=2020-11-01&enddate=2020-11-30`
        );

        this.healthCenters = this.allHealthCentersData.map((s) => ({
          value: s.Name,
          text: `${s.Name} 類別:${s.TCategory}`,
          tCategory: s.TCategory,
        }));
        this.healthCenters.sort(function(a, b) {
          if (a.tCategory > b.tCategory) return 1;
          if (a.tCategory < b.tCategory) return -1;
        });
      } catch (e) {
        alert(e);
      }
    },
    checkStringField(str) {
      return str && str.length;
    },
    submitForm() {
      //wait for APIs

      //myHealthCenter 選擇衛生所
      //myParticipate 選擇參與類別
      //selectedTimeStr 請選擇時段
      //myTelePhone 市內電話
      //myCellPhone 手機
      //myEmail
      //myLine
      //myContactName 緊急聯絡人姓名
      //myFacebook Facebook帳號
      //myContactRel 緊急聯絡人和本人關係為
      //myAddress 通訊地址
      //myBankCode 銀行代碼
      //myAccount  戶名
      //myBankName 銀行帳號

      this.curStep = 3;
    },
  },
  watch: {
    selectedTimeStr(v) {
      // console.log("selectedTimeStr", v);
    },
    currentTimeRange(v) {
      if (!this.days.length) return;
      let arr = [...this.days];
      let obj = arr.pop();
      obj.time = v;
      this.days.length = this.days.length - 1;
      this.days.push(obj);
    },
    myTrainee() {
      this.days = [];
      this.showTimeRange = false;
    },
    myHealthCenter() {
      let myHealthCenter = this.allHealthCentersData.find(
        (s) => s.Name == this.myHealthCenter
      );
      this.myTrainee = myHealthCenter.TCategory;
    },
  },
  async mounted() {
    this.getData();
  },
  beforeMount() {},
};
</script>

<style scoped lang="scss">
.my-steps {
  display: grid;
  grid-template-columns: repeat(3, 140px);
}

.clips-left {
  transform: translateX(-29px);
}
.clips-left2 {
  transform: translateX(-59px);
}
.clip-others {
  clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
  background: #bababa;
  color: var(--dark);
  text-align: center;
  line-height: 50px;
  width: 140px;
  height: 50px;
}
.selected-clip {
  clip-path: polygon(75% 0%, 100% 51%, 75% 100%, 0% 100%, 0 49%, 0 0);
  width: 140px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: #7e2ca3;
  color: white;
}
.main-grid-dtc {
  display: grid;
  grid-template-columns: 385px 205px 205px;
  grid-gap: 15px;
  position: relative;
  > div {
    height: 38px;
  }
  .nextStep {
    position: absolute;
    top: 53px;
    left: 840px;
  }
  .chooseTime {
    position: relative;
    .calendarClass {
      position: absolute;
      top: 7px;
      right: 15px;
      z-index: 999;
      color: #7e2ca3;
    }
  }
}
main .custom-select {
  border: 1px solid #ced4da !important;
  height: 38px;
  padding: 6px 12px 6px 0px;
  // padding: 6px 12px;
  font-size: 1rem !important;

  > div > div > div.input-group-text {
    width: 195px;
  }
}

main.main-grid-dtc {
  > div > div > div.input-group-text {
    width: 100px;
  }
  > div.chooseTime > div.input-group > div > div.input-group-text {
    width: 100px;
  }
}

.main-grid-step2 {
  display: grid;
  grid-template-columns: 550px 450px;
  grid-gap: 15px;
  border: 1px solid #eaecef;
  border-radius: 5px;
  width: 1066px;
  > div {
    height: 38px;
  }
  > div > div > div.input-group-text {
    width: 195px;
  }
}
.gradeStyle {
  width: 340px;
  margin-left: 10px;
}
.top-sec {
  position: relative;
}
.left-steps {
  position: absolute;
  top: 70px;
  left: 0.6rem;
  display: grid;
  grid-template-rows: max-content 69px max-content 900px max-content;
  .step-num {
    border-radius: 50%;
    color: white;
    background: lighten(#965ff0, 20%);
    color: white;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
  }
  .step-line {
    width: 2px;
    justify-self: center;
    background: lighten(#965ff0, 20%);
  }
  .active,
  .active-line {
    background: lighten(#965ff0, 0%);
  }
}
#popover-target-1.form-control[readonly] {
  background-color: #ffffff;
}
.my-mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  opacity: 0;
}
</style>

<style>
#my-2-popup {
  background: none;
  border: none;
}
</style>
